<template>
    <div :id="playerId" :style="playerStyle"></div>
</template>
  
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
    file: {
        type: String,
        required: true,
    },
    playlist: {
        type: Array,
        default: () => [],
    },
    autostart: {
        type: Boolean,
        default: true,
    },
    width: {
        type: [Number, String],
        default: '100%'
    },
    height: {
        type: [Number, String],
        default: '100%'
    },
    muted: {
        type: Boolean,
        default: true // Enable muted by default to support autoplay
    }
});

const emit = defineEmits(['ready', 'error', 'play', 'pause', 'complete', 'playlistItem', 'playlistComplete']);
const playerId = ref('jwplayer-' + Math.random().toString(36).substr(2, 9));
let playerInstance = null;
let retryCount = 0;
const MAX_RETRIES = 3;

const playerStyle = {
    width: typeof props.width === 'number' ? props.width + 'px' : props.width,
    height: typeof props.height === 'number' ? props.height + 'px' : props.height,
    background: '#000',
};

const initializePlayer = async () => {
    if (typeof jwplayer === 'undefined') {
        console.error('JW Player not loaded');
        emit('error', { instance: playerInstance, message: 'JW Player failed to load' });
        return;
    }

    try {
        if (!props.file && !props.playlist.length) {
            throw new Error('No media URL provided');
        }

        // Log URL for debugging
        console.log('Setting up player with URL:', props.file);

        // Set license key from env or use default
        if (!jwplayer.key) {
            jwplayer.key = import.meta.env.VITE_JW_KEY || "W7zSm81+mmIy/nXeHIq7JpXJDWNKdzwOX9LGjeqPxOjql43JqNcMQg==";
        }

        let setupConfig = {
            autostart: props.autostart,
            mute: props.muted,
            width: props.width,
            height: props.height,
            cast: {},
            stretching: 'uniform',
            preload: 'auto',
            playbackRateControls: true,
            primary: 'html5',
            // HLS specific configurations
            hlshtml: true,
            androidhls: true,
            type: undefined,
            fallback: true,
            // Performance settings
            bufferlength: 3,
            // Retry settings
            retryQos: true,
            minRetryTimeout: 2000,
            maxRetryTimeout: 10000
        };

        if (props.playlist.length) {
            setupConfig.playlist = props.playlist;
        } else {
            setupConfig.file = props.file;
            let isHLS = props.file.endsWith(".m3u8");
            setupConfig.type = isHLS ? 'hls' : undefined;
        }

        playerInstance = jwplayer(playerId.value);
        await playerInstance.setup(setupConfig);

        // Monitor stream status
        playerInstance.on('buffer', (e) => {
            console.log('Buffer state:', e);
        });

        playerInstance.on('meta', (e) => {
            console.log('Stream metadata:', e);
        });

        playerInstance.on('firstFrame', () => {
            console.log('First frame rendered');
            retryCount = 0; // Reset retry count on successful playback
        });

        playerInstance.on('playlistItem', (e) => {
            console.log('Playlist item:', e);
            emit('playlistItem', { instance: playerInstance, item: e.item, index: e.index, type: e.type });
        });

        playerInstance.on('playlistComplete', () => {
            console.log('Playlist completed');
            emit('playlistComplete', { instance: playerInstance });
        });

        // Enhanced error handling
        playerInstance.on('setupError', (e) => {
            console.error('Setup error:', e);
            emit('error', {
                instance: playerInstance,
                message: `Failed to setup player: ${e.message || 'Unknown error'}`,
                code: e.code
            });
        });
        
        playerInstance.on('playAttemptFailed', function (event) {
            console.log(event);
            emit('error', { instance: playerInstance, message: 'Failed to start playback: ' + event.message, code: event.code });
        });

        // Specific error handling for common issues
        playerInstance.on('error', (e) => {
            console.error('Playback error:', e);
            let errorMessage = 'An error occurred during playback';
            
            switch (e.code) {
                case 100012:
                    errorMessage = 'Unable to load media: The stream URL is invalid or not accessible';
                    break;
                case 232404:
                    errorMessage = 'No stream available: Please wait for the stream to start';
                    break;
                case 232011:
                    errorMessage = 'Stream not found or access denied';
                    break;
                default:
                    errorMessage = `Playback error (${e.code}): ${e.message}`;
            }
            
            emit('error', { instance: playerInstance, message: errorMessage, code: e.code });
            handlePlaybackError();
        });

        playerInstance.on('ready', () => {
            console.log('Player ready');
            emit('ready', { instance: playerInstance });
        });

        playerInstance.on('play', () => {
            console.log('Playback started');
            emit('play', { instance: playerInstance });
        });

        playerInstance.on('pause', () => {
            emit('pause', { instance: playerInstance } );
        });

        playerInstance.on('complete', () => {
            emit('complete', { instance: playerInstance });
        });

    } catch (error) {
        console.error('Player setup error:', error);
        emit('error', { instance: playerInstance, message: 'Failed to setup player: ' + error.message });
    }
};

const handlePlaybackError = () => {
    if (retryCount < MAX_RETRIES) {
        retryCount++;
        console.log(`Retrying playback attempt ${retryCount}/${MAX_RETRIES}`);
        setTimeout(() => {
            if (playerInstance) {
                if (props.playlist.length) {
                    playerInstance.load(props.playlist);
                } else {
                    playerInstance.load([{
                        file: props.file
                    }]);
                }
            }
        }, 2000 * retryCount); // Increase delay with each retry
    } else {
        console.error('Max retry attempts reached');
        emit('error', { instance: playerInstance, message: 'Failed to start playback after multiple attempts' });
    }
};

onMounted(async () => {
    if (typeof jwplayer === 'undefined') {
        const script = document.createElement('script');
        script.src = 'https://cdn.jwplayer.com/libraries/KbvJMbFv.js';
        script.async = true;
        script.crossOrigin = 'anonymous';
        
        script.onload = () => {
            console.log('JW Player script loaded');
            initializePlayer();
        };
        
        script.onerror = (error) => {
            console.error('Failed to load JW Player script:', error);
            emit('error', { instance: playerInstance, message: 'Failed to load player script' });
        };
        
        document.head.appendChild(script);
    } else {
        initializePlayer();
    }
});

onBeforeUnmount(() => {
    if (playerInstance) {
        playerInstance.remove();
    }
});
</script>
  
<style scoped>
div {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
}

:deep(.jw-error-msg) {
    margin: 0 !important;
    text-align: center;
}

:deep(.jw-error) {
    color: white !important;
}

:deep(.jw-state-error) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}

:deep(.jw-title) {
    text-align: center;
}
</style>
